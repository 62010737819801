const __request = require(`./__request/flight-admin/__request_contentType_json`);

// 航空公司查询
// 接口文档地址：http://183.58.24.99:9999/swagger-ui.html?tdsourcetag=s_pcqq_aiomsg#/
const citys = (pParameter) => {
  if (!pParameter) pParameter = {};
  const airLineCode = pParameter.airLineCode || ''
  const airLineName = pParameter.airLineName || ''
  const currentPage = pParameter.currentPage || '1'
  const pageSize = pParameter.pageSize || '10'
  pParameter = {
    method: 'post',
    // urlSuffix: '/flight/domestic/airlines',
    urlSuffix:"/base/dom/airlines",
    data: {
      airLineCode,
      airLineName,
      currentPage,
      pageSize
    }
  }

  return __request(pParameter)
}
module.exports = citys;
