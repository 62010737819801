//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "air-line",
  data() {
    return {
      airLine: ""
    };
  },
  mounted() {},
  props: {
    data: Array,
    airLineCode: String
  },
  watch: {
    data(val) {
      if (this.airLineCode) {
        this.getAirLine();
      }
    },
    airLine(val) {
      if (!val) {
        this.$emit("selectItem", "");
      }
    }
  },
  methods: {
    getAirLine() {
      this.data.map(item => {
        if (item.airlineCode === this.airLineCode) {
          this.airLine = "(" + item.airlineCode + ")" + item.airCompanyName;
        }
      });
    },
    querySearch(queryString, cb) {
      var restaurants = this.data;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return res => {
        if (
          res.airlineCode.toUpperCase().indexOf(queryString.toUpperCase()) !==
          -1
        ) {
          return res;
        } else if (res.airCompanyName.indexOf(queryString) !== -1) {
          return res;
        }
      };
    },
    handleSelect(item) {
      this.$emit("selectItem", item.airlineCode);
    }
  }
};
