import { render, staticRenderFns } from "./fareType.vue?vue&type=template&id=576e2592&scoped=true&"
import script from "./fareType.vue?vue&type=script&lang=js&"
export * from "./fareType.vue?vue&type=script&lang=js&"
import style0 from "./fareType.vue?vue&type=style&index=0&id=576e2592&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "576e2592",
  null
  
)

export default component.exports