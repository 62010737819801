//
//
//
//
//
//
//

export default {
    data (){
        return{
            num:this.defautlNum || 0,
        }
    },
    watch:{
        defautlNum(val){
            this.num = val
            
        },
    },
    props:['limitNum','type','defautlNum'],
    created(){
    },
    methods:{
        add(){
            let limit = this.limitNum?this.limitNum:9
            if(this.num>=limit)return
            this.num++
            let data = {
                type:this.type,
                num:this.num
            }
            this.$emit('getNumber',data)
        },
        minus(){
            if(this.num<=0)return
            this.num--
            let data = {
                type:this.type,
                num:this.num
            }
            this.$emit('getNumber',data)
        },
        inputDetection(val){
            let limit = this.limitNum?this.limitNum:9
            let reg = /^-?\d+$/
            let num = Number(val)
            if(reg.test(val)){
                val>=limit?this.num = limit:''
            }else{
                this.num = 0
            }
           
        }
    }
}
