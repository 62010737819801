//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 城市列表查询
const citys = require(`../../../../lib/data-service/flight/flight_domestic_citys`);

const inlandList = require(`../../../../lib/data-service/flight/flight_domestic_airlines`);
import {
  internationalList,
  internationalCity,
  internationalAirLines
} from "@/lib/data-service/flight/fligthInternational";
import { setSession } from "@/lib/deprecated/utils/caching";
import FareBtn from "./components/fareType";
import scrollTop from "@/lib/common-service/scrollTop";
import CalendarBox from "../components/calendar";
import airLineBox from "../components/airLine";
import sortBox from "../components/sortBox";

// 国内航班列表查询
const _ = require(`underscore`);
export default {
  data() {
    return {
      pickerOptions: this.setEndTime1(),
      flightSartSort: true,
      flightEndSort: false,
      airType: 1, // 航程类型
      airTypeShow: true,
      loadingShow: 0,
      pickerOptions2: this.setEndTime(), //  返回时间
      time1: null, //  出发时间
      time2: null, //  返回时间
      time2Show: true, // 返回时间是否可用
      timeRoll: 1569772800000, //滚动条时间
      starCity: "", // 出发城市
      starCity1: "", // 展示出发城市
      starTwoCity: "", // 第二出发城市
      starTwoCity1: "", // 第二展示出发城市
      endCity: "", // 目的城市
      endCity1: "", // 展示目的城市
      endTwoCity: "", // 第二目的城市
      endTwoCity1: "", // 第二展示目的城市
      cityList: [], // 城市字母列表
      chinaCityList: [], // 中国城市字母列表
      chinaRestaurants: [], // 中国城市机场列表
      restaurants: [], // 所有城市机场列表
      arrowsInfoShow: [],
      totalAll: 0,
      cityListH: ["热门", "ABCD", "EFGH", "JKLM", "NOPQRS", "TUVWX", "YZ"], // 热门列表
      HotCity: [], //  热门城市列表
      cityListHShow: 0, //  热门列表index
      starList: false, //  出发城市列表显示
      EndList: false, //  目的城市列表显示
      starTwoList: [false], //  出发城市列表显示
      EndTwoList: [false], //  目的城市列表显示
      ClassType: 0, //  舱位选择
      typeShow: 1,
      foucStatu: false,
      fareInfoShow: null,
      clauseShow: null,
      takeOffTime: [
        { value: "", label: "全部" },
        { value: "00:00-05:59", label: "00:00-05:59" },
        { value: "06:00-11:59", label: "06:00-11:59" },
        { value: "12:00-17:59", label: "12:00-17:59" },
        { value: "18:00-23:59", label: "18:00-23:59" }
      ],
      multipassAir: [
        {
          starCityMore: "",
          endCityMore: "",
          starShow: false,
          endShow: false,
          time1: ""
        }
      ], //过程
      ClassTypeList: [
        //  舱位列表
        {
          value: 1,
          label: "头等舱"
        },
        {
          value: 2,
          label: "公务舱"
        },
        {
          value: 3,
          label: "经济舱"
        }
      ],
      psgType: 1, //  乘机人类型
      airlines: "", //  航司
      airlinesList: [
        //  航司列表
        {
          airlineCode: "",
          airCompanyName: "不限",
          airCompanyEnglistName: "",
          airCompanyShortName: "",
          airlineCode: "",
          countryState: ""
        }
      ],
      sortShow: [true, false],
      timeIndex: 0, // 已选的class
      flightsList: [], // 航班列表
      international: {
        adult: 0,
        child: 0,
        baby: 0
      },
      loading: false, //  加载中
      flightsIndex: 0, //  当前展示的列表
      flightsShow: false, // 是否展示
      reserveType: "预定", // 按钮状态
      params: {
        currentPage: 1,
        pageSize: 10,
        takeOffInterval: ""
      }, // 搜索传参
      itemOne: [], //  第一行程
      image_url_airline_blank_map: `https://lyn.oss-cn-shenzhen.aliyuncs.com/temp/airline-blank-map.jpg`,
      weekList: [],
      weekBtnStatu: false,
      saveWeek: "",
      taxStatu: true,
      trunkShow: null,
      airCompanyText: "",
      flightBRInfosMore: [],
      initStatu: 0
    };
  },

  methods: {
    flightTimeSort(type) {
      if (this.flightsList.length == 0) return;
      if (type === "start") {
        this.flightSartSort = !this.flightSartSort;
        this.params.orderField = "departTime";
        this.sortShow = [true, false];
      } else {
        this.params.orderField = "arrivalTime";
        this.flightSartSort = !this.flightSartSort;
        this.sortShow = [false, true];
      }
      this.flightSartSort
        ? (this.params.orderType = "asc")
        : (this.params.orderType = "desc");
      this.search();
    },
    // 日期选择
    setEndTime() {
      let _this = this;
      return {
        disabledDate: time => {
          if (_this.time1) {
            return time.getTime() < _this.time1;
          } else {
            return time.getTime() < Date.now() - 1000 * 3600 * 24;
          }
        }
      };
    },
    handleSizeChange() {},
    clickDate(val) {
      this.time1 = new Date(val);
      // this.multipassItem.time1 = new Date(val)
      if (this.airType == 2) {
        let _a = new Date(this.time1).valueOf();
        let _b = new Date(this.time2).valueOf();
        if (_a > _b) {
          this.time2 = null;
        }
      } else if (this.airType == 3) {
        this.multipassAir[0].time1 = this.time1;
      }
      this.saveWeek = val;
      this.initStatu = 0;
      this.search();
    },
    initSearch() {
      // if(this.itemOne.length>0){
      //   this.saveWeek = this.$moment(this.time2).format('YYYY-MM-DD')
      // }else{
      //   this.saveWeek = this.$moment(this.time1).format('YYYY-MM-DD')
      // }
      this.initStatu++;
      this.search();
    },
    selectItem(val) {
      this.airlines = val;
    },
    handleCurrentChange(val) {
      this.params.currentPage = val;
      this.search("pageing");
      scrollTop(500);
    },
    querySearchAirLines(queryString, cb) {
      let restaurants = this.airlinesList;
      let results;
      results = queryString
        ? restaurants.filter(this.createStateFilterAirLines(queryString))
        : [];
      cb(results);
    },
    handleSelectAirLines(item) {
      this.airlines = item.airlineCode;
    },
    createStateFilterAirLines(queryString) {
      return restaurant => {
        if (
          restaurant.airCompanyEnglistName.indexOf(queryString.toUpperCase()) !=
          -1
        ) {
          restaurant.value = restaurant.airCompanyName;
          return restaurant;
        } else if (restaurant.airCompanyName.indexOf(queryString) != -1) {
          restaurant.value = restaurant.airCompanyName;
          return restaurant;
        } else if (
          restaurant.airlineCode.indexOf(queryString.toUpperCase()) !== -1
        ) {
          restaurant.value = restaurant.airCompanyName;
          return restaurant;
        }
      };
    },
    createFilter(queryString) {
      let pattern2 = new RegExp("[A-Za-z]+");
      if (pattern2.test(queryString)) {
        return restaurant => {
          if (restaurant.spell && restaurant.code) {
            if (
              restaurant.spell
                .toUpperCase()
                .indexOf(queryString.toUpperCase()) != -1
            ) {
              return restaurant;
            } else if (
              restaurant.code.indexOf(queryString.toUpperCase()) != -1
            ) {
              return restaurant;
            }
          }
        };
      } else {
        return restaurant => {
          if (restaurant.name.indexOf(queryString) != -1) {
            return restaurant;
          }
        };
      }
    },
    addMultipass() {
      if (this.multipassAir.length > 5)
        return this.$message({ type: "warning", message: "多程航最多选择5条" });
      let obj = {
        starCityMore: "",
        endCityMore: "",
        starShow: false,
        endShow: false,
        time1: ""
      };
      this.multipassAir.push(obj);
      this.$forceUpdate();
    },
    minusMultipass(index) {
      if (this.multipassAir.length === 1) return;
      this.multipassAir.pop();
      console.log(this.multipassAir);
    },
    setEndTime1() {
      let _this = this;
      return {
        disabledDate: time => {
          if (_this.time2 && this.airType === 2) {
            return (
              time.getTime() > _this.time2 ||
              time.getTime() < Date.now() - 1000 * 3600 * 24
            );
          } else {
            return time.getTime() < Date.now() - 1000 * 3600 * 24;
          }
        }
      };
    },
    pickerOptionsList(index) {
      let _this = this;
      let list = this.multipassAir;
      return {
        disabledDate: time => {
          return time.getTime() < Date.now() - 1000 * 3600 * 24;
        }
      };
      //  switch (index){
      //    case 0:
      //      return {disabledDate: time => {
      //               return time.getTime() < Date.now() - 1000 * 3600 * 24;
      //           }
      //      }
      //     break;
      //    case 1:
      //      return {disabledDate: time => {
      //               return time.getTime() < Date.now() - 1000 * 3600 * 24;
      //           }
      //      }
      //     break;
      //   default:
      //     break;
      //  }
      //  return verifyDate()
      function verifyDate() {
        return {
          disabledDate: time => {
            if (list[0].time1) {
              if (list[1] && list[0].time1) {
                return time.getTime() - 1000 * 3600 * 24 < list[0].time1;
              } else if (list[2]) {
                return time.getTime() - 1000 * 3600 * 24 < list[1].time1;
              } else if (list[3]) {
                return time.getTime() - 1000 * 3600 * 24 < list[2].time1;
              } else if (list[4]) {
                return time.getTime() - 1000 * 3600 * 24 < list[3].time1;
              } else if (list[5]) {
                return time.getTime() - 1000 * 3600 * 24 < list[4].time1;
              }
              return time.getTime() < Date.now() - 1000 * 3600 * 24;
            } else {
              return time.getTime() < Date.now() - 1000 * 3600 * 24;
            }
          }
        };
      }
    },
    querySearch(queryString, cb, item, type) {
      if (type) {
        if (type == "start") {
          item.starShow = false;
        } else {
          item.endShow = false;
        }
        this.$forceUpdate();
      }
      var res = this.restaurants;
      var results = queryString
        ? res.filter(this.createFilter(queryString))
        : "";
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    // 获取城市接口
    loadAllCity() {
      internationalCity().then(res => {
        this.restaurants = [...res.citys];
        let cityList = [];
        for (let i = 0; i < res.citys.length; i++) {
          if (res.citys[i].airports === false) {
            cityList.push(res.citys[i]);
          }
          if (
            res.citys[i].isHot === "Y" &&
            this.HotCity.length < 23 &&
            res.citys[i].airports === false
          ) {
            this.HotCity.push(res.citys[i]);
          }
        }
        this.cityList = _.chain(cityList)
          .groupBy(function(m) {
            return m.initial;
          })
          .value();
      });
    },
    // 获取中国城市
    loadChinaCity() {
      // chinaCityList
      citys().then(res => {
        let restaurants = [...res.citys];
        let cityList = [];
        for (let i = 0; i < res.citys.length; i++) {
          if (res.citys[i].airports === false) {
            cityList.push(res.citys[i]);
          }
          /*if (
                  res.citys[i].isHot === "Y" &&
                  this.HotCity.length < 23 &&
                  res.citys[i].airports === false
          ) {
            this.HotCity.push(res.citys[i]);
          }*/
        }
        this.chinaCityList = _.chain(cityList)
                .groupBy(function(m) {
                  return m.initial;
                })
                .value();
        this.chinaRestaurants = this.setCityName(restaurants, cityList);
      });
    },
    _getWeek(val) {
      let arr = [];
      val ? val : "";
      for (let i = 0; i < 7; i++) {
        let dateTime = this.$moment(val)
          .subtract(-i, "days")
          .format("YYYY-MM-DD");
        let week = this.$moment(val).subtract(-i, "days")._d;
        week = week.getDay();
        week =
          week === 0
            ? "星期日"
            : week === 1
              ? "星期一"
              : week === 2
                ? "星期二"
                : week === 3
                  ? "星期三"
                  : week === 4
                    ? "星期四"
                    : week === 5
                      ? "星期五"
                      : "星期六";
        let obj = {
          week: week,
          date: dateTime,
          statu: false
        };
        arr.push(obj);
      }
      this.weekList = arr;
      if (this.saveWeek) {
        arr.map((item, index) => {
          item.date === this.saveWeek ? (item.statu = true) : "";
        });
      }
    },
    // 城市转换
    transitionCity() {
      let city = this.starCity;
      this.starCity = this.endCity;
      this.endCity = city;
      let city1 = this.starCity1;
      this.starCity1 = this.endCity1;
      this.endCity1 = city1;
    },
    transitionCity2(val) {
      let city = this.starTwoCity;
      let star = val.starCityMore;
      let startCode = val.starCityCode;
      val.starCityMore = val.endCityMore;
      val.starCityCode = val.endCityCode;
      val.endCityMore = star;
      val.endCityCode = startCode;
    },

    // 获取出发城市
    getstarCity(item) {
      this.starCity = item.airports
        ? item.code
        : item.cityCode
          ? item.cityCode
          : item.code;
      this.starCity1 = item.name + "(" + item.code + ")";
    },
    getstarTwoCity(item, index) {
      // this.starTwoCity = item.airports ? item.code : item.cityCode ? item.cityCode : item.code
      // this.starTwoCity1 = item.name + '(' + item.code + ')'
      this.multipassAir[index].starCityMore = item.name + "(" + item.code + ")";
      this.multipassAir[index].starCityCode = item.code;
    },
    // 获取目的城市
    getendCity(item) {
      this.endCity = item.airports
        ? item.code
        : item.cityCode
          ? item.cityCode
          : item.code;
      this.endCity1 = item.name + "(" + item.code + ")";
    },
    getendTwoCity(item, index) {
      // this.endTwoCity = item.airports ? item.code : item.cityCode ? item.cityCode : item.code
      // this.endTwoCity1 = item.name + '(' + item.code + ')'
      this.multipassAir[index].endCityMore = item.name + "(" + item.code + ")";
      this.multipassAir[index].endCityCode = item.code;
    },
    // 获取点击的城市
    getClickCity(item, data, index) {
      // console.log(item)
      if (data === "starCity") {
        this.starCity = item.airports
          ? item.code
          : item.cityCode
            ? item.cityCode
            : item.code;
        this.starCity1 = item.name + "(" + item.code + ")";
        this.starList = false;
      }
      if (data === "endCity") {
        this.endCity = item.airports
          ? item.code
          : item.cityCode
            ? item.cityCode
            : item.code;
        this.endCity1 = item.name + "(" + item.code + ")";
        this.EndList = false;
      }
      if (data === "starTwoCity") {
        // this.starTwoCity = item.airports ? item.code : item.cityCode ? item.cityCode : item.code
        // this.starTwoCity1 = item.name + '(' + item.code + ')'
        this.multipassAir[index].starCityMore =
          item.name + "(" + item.code + ")";
        this.multipassAir[index].starCityCode = item.code;
        this.multipassAir[index].starShow = false;
        this.$forceUpdate();
      }
      if (data === "endTwoCity") {
        // this.endTwoCity = item.airports ? item.code : item.cityCode ? item.cityCode : item.code
        // this.endTwoCity1 = item.name + '(' + item.code + ')'
        this.multipassAir[index].endCityMore =
          item.name + "(" + item.code + ")";
        this.multipassAir[index].endCityCode = item.code;
        this.multipassAir[index].endShow = false;
        this.$forceUpdate();
        // this.EndTwoList = false
      }
    },
    // 获取焦点显示热门的城市
    async CityFocus(data, index) {
      if (this.starCity1 === "" && data === "starCity") {
        this.starList = true;
        this.EndList = false;
        // this.starTwoList = false
        // this.EndTwoList = false
      }
      if (this.endCity1 === "" && data === "endCity") {
        this.starList = false;
        this.EndList = true;
        // this.starTwoList = false
        // this.EndTwoList = false
      }
      if (data === "starTwoCity" && !this.multipassAir[index].starCityMore) {
        this.starList = false;
        this.EndList = false;
        this.multipassAir.map(item => {
          item.starShow = false;
        });
        this.$set(this.multipassAir[index], "starShow", true);
      }
      if (data === "endTwoCity" && !this.multipassAir[index].endCityMore) {
        this.starList = false;
        this.EndList =
          false /
          this.multipassAir.map(item => {
            item.endShow = false;
          });
        this.$set(this.multipassAir[index], "endShow", true);
      }
    },
    noneShow() {
      let Starfocus = document.getElementById("starCity");
      let endCity = document.getElementById("endCity");
      if (document.activeElement.id !== "starTwoCity") {
        this.multipassAir.map(item => {
          item.starShow = false;
        });
      }
      if (document.activeElement.id !== "endTwoCity") {
        this.multipassAir.map(item => {
          item.endShow = false;
        });
      }
      if (Starfocus !== document.activeElement) {
        this.starList = false;
      }
      if (endCity !== document.activeElement) {
        this.EndList = false;
      }
    },
    // 阻止事件冒泡
    stop() {},
    // 获取航司列表
    getAirlines() {
      internationalAirLines().then(data => {
        // console.log(data)
        let airlinesList = [...this.airlinesList, ...data.pageResult.pageData];
        airlinesList.map(item => {
          item.value = "(" + item.airlineCode + ")" + item.airCompanyName;
        });
        this.airlinesList = airlinesList;
      });
    },
    // 搜索航班列表
    search(text) {
      let people;
      if (
        (this.params.babyCount || this.params.childCount) &&
        !this.params.adultCount
      ) {
        return this.$message({
          type: "warning",
          message: "请至少选择一名成人"
        });
      }
      if (!this.params.adultCount) {
        return this.$message({ type: "warning", message: "请选择乘客" });
      }
      people =
        (this.params.adultCount || 0) +
        (this.params.childCount || 0) +
        (this.params.babyCount || 0);
      if (people > 9)
        return this.$message({
          type: "warning",
          message: "最大乘客数量为9人,请重新选择！"
        });
      let params = {
        adultCount: this.params.adultCount || 0,
        airLineCode: this.airlines,
        babyCount: this.params.babyCount || 0,
        cabinRank: this.ClassType,
        childCount: this.params.childCount || 0,
        currentPage: this.params.currentPage,
        flightBRInfos: [
          {
            beginDate: this.$moment(this.time1).format("YYYY-MM-DD"),
            eCityCode: this.endCity,
            sCityCode: this.starCity
          }
        ],
        flightType: this.airType || 1,
        isFlightDirect: 0,
        orderField: this.params.orderField || "departTime",
        orderType: this.params.orderType || "asc",
        pageSize: this.params.pageSize,
        takeOffInterval: this.params.takeOffInterval
      };

      if (this.airType === 2 && this.time2 === null) {
        return this.$message({
          message: "请选择回程时间",
          type: "warning"
        });
      }
      if (this.airType == 1) {
        if (!this.time1) {
          return this.$message({ message: "请选择出发时间", type: "warning" });
        }
      }
      if (this.airType === 2) {
        if (!this.time1)
          return this.$message({ message: "请选择出发时间", type: "warning" });
        params.flightBRInfos.push({
          beginDate: this.$moment(this.time2).format("YYYY-MM-DD"),
          eCityCode: this.starCity,
          sCityCode: this.endCity
        });
      }
      if (this.airType === 1 || this.airType === 2) {
        // 判断是否两者都是国内地点
        let arrCity = [];
        for (let i in this.chinaCityList) {
          arrCity.push(...this.chinaCityList[i]);
        }
        let code = arrCity.map(item => {
          return item.code;
        });
        if (code.includes(this.starCity) && code.includes(this.endCity)) {
          console.log('跳到国内航班搜索')
          let adultCount = this.params.adultCount
          let babyCount = this.params.babyCount
          let childCount = this.params.childCount
          let psgType = 1
          if (adultCount > 0 && babyCount === 0 && childCount === 0) {
            psgType = 1
          } else if (adultCount < 0 && (babyCount > 0 || childCount > 0)) {
            psgType = 2
          } else if (this.airType === 1 && adultCount > 0 && (babyCount > 0 || childCount > 0)) {
            psgType = 3
          } else {
            psgType = 1
          }
          let params = {
            airType: this.airType,
            time1: this.time1,
            time2: this.airType === 1 ? null : this.time2,
            time2Show: this.airType === 1,
            psgType: psgType,
            ClassType: this.ClassType,
            airlines: '',
            starCity: this.starCity,
            starCity1: this.starCity1,
            endCity: this.endCity,
            endCity1: this.endCity1
          };
          console.log(params);
          this.$router.push({
            name: "air-list",
            query: {
              airdata: JSON.stringify(params)
            }
          });
          return
        }
      }
      if (this.airType === 3) {
        let statu = false;
        let fligthArr = [];
        this.multipassAir.some(item => {
          for (let i in item) {
            if (item[i] === "") {
              statu = true;
              break;
            }
          }
          fligthArr.push({
            beginDate: this.$moment(item.time1).format("YYYY-MM-DD"),
            eCityCode: item.endCityCode,
            sCityCode: item.starCityCode
          });
          return statu;
        });
        if (statu)
          return this.$message({ type: "warning", message: "请完善航班信息" });
        //  this.flightBRInfosMore = fligthArr
        params.flightBRInfos = fligthArr;
        this.saveWeek = this.$moment(fligthArr[0].beginDate).format(
          "YYYY-MM-DD"
        );
        this._getWeek(fligthArr[0].beginDate);
      }
      if (text != "pageing") {
        params.currentPage = 1;
      }
      // return
      this.params = params;
      this.weekBtnStatu = false;
      this.getflights(params);
      if (this.airType != 3) {
        this._getWeek(this.time1);
      }
    },
    // 获取航班列表
    getflights(params) {
      this.loading = true;
      internationalList(params)
        .then(data => {
          this.loadingShow = this.loadingShow + 1;
          this.totalAll = data.pageResult.totalCount;
          this.loading = false;
          this.flightsList = data.pageResult.pageData;
          this.airTypeShow = true;
          let obj = JSON.parse(JSON.stringify(data.pageResult.pageData));
          this.arrowsInfoShow = obj.map((item, index) => {
            return item.segmentList.map(item => {
              return false;
            });
          });
        })
        .catch(err => {
          this.loading = false;
          this.loadingShow = this.loadingShow + 1;
          this.flightsList = [];
        });
    },
    // 输出周几
    Getweek(index) {
      let str = [
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
        "星期日"
      ];
      let week =
        Number(
          this.$moment(this.timeRoll + index * 1000 * 3600 * 24).format("E")
        ) - 1;
      return str[week];
    },
    // 输出日期
    getTime(index) {
      let day = 1000 * 3600 * 24;
      let daynub =
        (this.timeRoll - -new Date(new Date().setHours(0, 0, 0, 0))) / day;
      if (daynub >= 3) {
        index = index - 2;
        this.timeIndex = 2;
        return this.$moment(this.timeRoll + index * 1000 * 3600 * 24).format(
          "YYYY-MM-DD"
        );
      } else {
        index = index - daynub;
        this.timeIndex = daynub;
        return this.$moment(this.timeRoll + index * 1000 * 3600 * 24).format(
          "YYYY-MM-DD"
        );
      }
    },
    // 点击时间滚动条内容获取时间
    setTime(val) {
      this.weekList.map(item => {
        item.statu = false;
      });
      val.statu = true;
      this.weekBtnStatu = false;
      this.saveWeek = val.date;
      this.params.beginDate = val.date;
      this.params.currentPage = 1;
      this.getflights(this.params);
      this.time1 = new Date(val.date).valueOf();
      this.$set(this.multipassAir[0], "time1", new Date(val.date).valueOf());
    },
    // 时间按钮
    leftTime(val) {
      if (val == "next") {
        this._getWeek(this.weekList[6].date);
        this.weekBtnStatu = true;
      } else if (val == "last") {
        if (!this.weekBtnStatu) return;
        let date = this.$moment(this.weekList[0].date)
          .subtract("days", 6)
          .format("YYYY-MM-DD");
        let now = this.$moment(this.time1).format("YYYY-MM-DD");
        this._getWeek(date);
        if (date.valueOf() <= now.valueOf()) {
          this.weekBtnStatu = false;
          return;
        }
      }
    },
    getNumber(val) {
      val.type === "adult"
        ? (this.params.adultCount = val.num)
        : val.type === "child"
          ? (this.params.childCount = val.num)
          : (this.params.babyCount = val.num);
    },
    // 行程选择
    journeyType(listData) {
      this.$router.push({
        name: "international-air-order"
      });
      setSession("AIRINFO", listData);
      setSession("AIRSEGMENTINFO", listData.segmentList);
      setSession("ORDERINFO", this.params);
    }
  },
  watch: {
    airType() {
      if (this.airType === 1) {
        this.time2Show = true;
      } else {
        this.time2Show = false;
      }
      if (this.airType === 2 && this.time1 > this.time2) {
        this.time2 = null;
      }
      this.airTypeShow = false;
      this.itemOne = [];
    },
    airCompanyText(val) {
      if (!val) this.airlines = "";
    },

    // 出发城市1
    starCity1() {
      let Starfocus = document.getElementById("starCity");
      if (this.starCity1 !== "") {
        this.starList = false;
      }
      if (Starfocus === document.activeElement && this.starCity1 === "") {
        this.starList = true;
      }
    },
    // 目的城市1
    endCity1() {
      let endCity = document.getElementById("endCity");
      if (this.endCity1 !== "") {
        this.EndList = false;
      }
      if (endCity === document.activeElement && this.endCity1 === "") {
        this.EndList = true;
      }
    }
  },
  mounted() {
    this.loadAllCity();
    this.loadChinaCity()
  },
  components: { FareBtn, CalendarBox, airLineBox, sortBox },
  async created() {
    this.getAirlines();
    if (this.$route.query.airdata) {
      let params = JSON.parse(this.$route.query.airdata);
      this.airType = params.airType;
      (this.time1 = params.time1), //  出发时间
        (this.time2 = params.time2), //  返回时间
        (this.multipassAir[0].starCityMore = params.starCity1);
      this.multipassAir[0].starCityCode = params.starCity;
      this.multipassAir[0].endCityMore = params.endCity1;
      this.multipassAir[0].endCityCode = params.endCity;
      this.multipassAir[0].time1 = params.time1;
      (this.time2Show = params.time2Show), // 返回时间是否可用
        // this.timeRoll =  params.timeRoll, //滚动条时间
        (this.starCity = params.starCity), // 出发城市
        (this.starCity1 = params.starCity1), // 展示出发城市
        // this.starTwoCity =  params.starTwoCity,// 第二出发城市
        // this.starTwoCity1 =  params.starTwoCity1,// 第二展示出发城市
        (this.endCity = params.endCity), // 目的城市
        (this.endCity1 = params.endCity1), // 展示目的城市
        // this.endTwoCity =  params.endTwoCity,// 第二目的城市
        // this.endTwoCity1 =  params.endTwoCity1,// 第二展示目的城市
        // this.psgType =  params.psgType
        (this.params.adultCount = params.adultCount);
      this.params.babyCount = params.babyCount;
      this.params.childCount = params.childCount;
      console.log(this.params);
      this.ClassType = params.ClassType || 0; //舱位等级
      this.airlines = params.airlines;
      this.saveWeek = this.$moment(this.time1).format("YYYY-MM-DD");
      this.search();
      // if (params.time1 && params.starCity && params.endCity) {
      //     this.search()
      // } else {
      //   console.log(JSON.parse(this.$route.query.airdata))
      // }
    }
  }
};
